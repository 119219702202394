<template>
  <div class="pie">
    <viewChart
        :chartId="random"
        :option="options"
        :width="'0.58rem'"
        :height="'0.58rem'">
    </viewChart>
    <ol class="label">
        <li><span class="label_one"></span>在寝 {{data[0]}}%</li>
        <li><span class="label_two"></span>未在寝 {{data[1]}}%</li>
    </ol>
  </div>
</template>

<script>
import viewChart from '@/components/chart/view-chart'
export default {
    name:'dormitoryPie',
    components:{
        viewChart
    },
    props: {
        color:{
            type: Array
        },
        data:{
            type:Array
        }
    },
    data(){
        return {
            random:String(Math.random()),
            option : {
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: [
                            {value: 1048},
                            {value: 735}
                        ]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            let option =  JSON.parse(JSON.stringify(this.option));
            option.color = this.color;
            option.series[0].data = this.data;
            return option
        }
    }   
}
</script>

<style scoped>
.pie{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:0.12rem;
    color:#B1CEF4;
}
.label{
    text-align: left;
}
.label li span{
    display: inline-block;
    width: 0.08rem;
    height:0.08rem;
    border-radius:0.08rem;
    margin-right:0.1rem;
}
.label_one{
    background-color: #4992FF;
}
.label_two{
    background-color: #FFB750;
}
</style>


