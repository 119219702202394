<template>
  <div class="container">
    <top></top>
    <alarm-scroll @click="openModal"/>
<!--     <el-button @click="showModuleSetting">设置</el-button>-->
    <div style="display: flex;flex-direction: row;padding:0.13rem 0.56rem">
      <div style="width: 5.04rem">
        <div style="height: 2.18rem;" v-for="(item,index) in module[0]" :key="index" @click="openModal(item)"
             :style="index > 0 ?'height: 2.44rem;margin-top: 0.2rem':'height: 2.44rem;'">
          <item-panel :title="item.templateName">
            <component v-bind:is="componentMapper[item.templateCode].component"
                       :data="componentMapper[item.templateCode].data" :alarmDetail="alarmDataList"/>
          </item-panel>
        </div>
      </div>
      <div style="width: 7.86rem;" ref="centerPanel">
        <el-carousel indicator-position="none" height="5.08rem" @change="onCarouselChange" ref="carousel" :interval="20000"
                     :autoplay="autoPlay">
          <el-carousel-item>
            <div style="position: relative;height: 100%">
              <div style="position:absolute;left: 0;width: 2rem;z-index: 9;bottom: 0;top: 0">
                <school-marquee :data="inSchoolDataList"/>
              </div>

              <div style="position:absolute;left: 0;right: 0;bottom:0;top: 0;">
                <div style="height: 100%;width: 100%">
                    <distribute-in-school ref="distributeInSchool" :city="city" :data="inSchoolDataList"/>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div style="height: 100%;width: 100%">
              <distribute ref="distribute" :city="city" :data="temperatureDataList"/>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div style="height: 100%;width: 100%">
              <distribute-alarm ref="distributeAlarm" :city="city" :data="alarmDataList"/>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div style="width:7.8rem;height: 1.08rem;margin:0 auto" class="distribute-tab">
          <div style="height: 0.37rem"></div>
          <div class="tab-wrapper" style="display: flex;justify-content: center">
            <div v-for="(item, index) in tabList" :key="index" @click="setCarouselActive(index)">
              <div style="width: 0.08rem;height: 0.08rem;border-radius: 0.08rem;background: #FFB750;margin: 0 auto;"
                   :style="index === carouselIndex ? 'background: linear-gradient(-90deg, #0E2C71, #FFB750);width: 0.3rem;':''">

              </div>
              <p
                style="color: #B1D9FF;font-size: 0.14rem;margin-top: 0.08rem;text-align: center;padding: 0 0.2rem;font-weight: bold">
                {{ item.label }}</p>
            </div>
          </div>
        </div>

        <div style="width: 7.86rem;margin: 0.2rem auto 0">
          <base-statistics ref="baseStatistics"/>
        </div>
      </div>
      <div style="width: 5.04rem">
        <div style="height: 2.18rem;" v-for="(item,index) in module[1]" :key="index" @click="openModal(item)"
             :style="index > 0 ?'height: 2.44rem;margin-top: 0.2rem':'height: 2.44rem;'">
          <item-panel :title="item.templateName">
            <component v-bind:is="componentMapper[item.templateCode].component"
                       :data="componentMapper[item.templateCode].data"/>
          </item-panel>
        </div>
      </div>
    </div>

      <left-drawer/>
      <bottom-tab-menu :moduleList="componentMapper" @menu-click="openModal" @sub-menu-click="openSettingModal"/>
    <fy-dialog ref="dialog" basic custom-class="transparent">
      <module ref="setting" @change="onModuleChange" @close="closeModuleSetting"/>
    </fy-dialog>

    <schoolDataModal :modelShow="inschoolMoal" @handelClose="close('inschoolMoal')"></schoolDataModal>
    <dormitoryModal :modelShow="dormModal" @handelClose="close('dormModal')"></dormitoryModal>
    <heatModal :modelShow="temperatureModal" @handelClose="close('temperatureModal')"></heatModal>
    <guardModal :modelShow="alarmInfoModal" @handelClose="close('alarmInfoModal')"></guardModal>
    <cloudPartyModal :modelShow="partyBuildModal" @handelClose="close('partyBuildModal')"></cloudPartyModal>
    <checkModal :modelShow="studentCheckModal" @handelClose="close('studentCheckModal')"></checkModal>

    <warningSetModal :modelShow="warningSetModal" @handelClose="warningSetModal=false"></warningSetModal>
    <homeSetModal :modelShow="homeSetModal" @handelClose="homeSetModal=false" @change="onModuleChange"></homeSetModal>

  </div>
</template>
<script>
import api from '../../api/cityDashboardData'
import { refreshTime } from '@/config/config'
import AMapBoundaries from '../../util/aMapBoundaries'
import * as echarts from 'echarts'
import schoolDataModal from '@/components/cityModal/schoolDataModal'
import dormitoryModal from '@/components/cityModal/dormitoryModal'
import heatModal from '@/components/cityModal/heatModal'
import guardModal from '@/components/cityModal/guardModal'
import cloudPartyModal from '@/components/cityModal/cloudPartyModal'
import checkModal from '@/components/cityModal/checkModal'
import warningSetModal from '@/components/cityModal/warningSetModal'
import homeSetModal from '@/components/cityModal/homeSetModal'
import refreshToken from '@/util/refreshToken'
export default {

  name: 'city',

  components: {
    schoolDataModal,
    dormitoryModal,
    heatModal,
    guardModal,
    cloudPartyModal,
    checkModal,
    warningSetModal,
    homeSetModal,
    top: () => import('./components/top'),
    alarmScroll: () => import('./components/alarmScroll'),
    itemPanel: () => import('./components/itemPanel'),
    realTimeStatistics: () => import('./components/realTimeStatistics'),
    alarm: () => import('./components/alarm'),
    dorm: () => import('./components/dorm'),
    studentAttendance: () => import('./components/studentAttendance'),
    temperatureMonitoring: () => import('./components/temperatureMonitoring'),
    baseStatistics: () => import('./components/baseStatistics'),
    classroom: () => import('./components/classroom'),
    bottomTabMenu: () => import('./components/bottomTabMenu'),
    'fy-dialog': () => import('./components/dialog'),
    setting: () => import('./components/setting'),
    module: () => import('./components/module'),
    distribute: () => import('./components/distribute'),
    'school-marquee': () => import('./components/marquee'),
    distributeInSchool: () => import('./components/distributeInSchool'),
    distributeAlarm: () => import('./components/distributeAlarm'),
    leftDrawer: () => import('./components/leftDrawer')
  },
  data () {
    return {
      inschoolMoal: false,
      alarmInfoModal: false,
      temperatureModal: false,
      partyBuildModal: false,
      dormModal: false,
      studentCheckModal: false,
      homeSetModal: false,
      warningSetModal: false,

      city: '福州市',
      showDialog: false,
      tabList: [
        { label: '在校数据', name: '' },
        { label: '体温异常', name: '' },
        { label: '校园报警', name: '' }
      ],
      districtSearch: null,
      districtBoundaries: {},
      districtTotal: 0,
      districtLoadLeft: 0,
      carouselIndex: 0,
      autoPlay: true,
      module: [[], []],
      componentMapper: {
        inschoolData: {
          title: '在校数据',
          component: 'realTimeStatistics',
          visible: 'inschoolMoal',
          data: {}
        },
        alarmInfoData: {
          title: '校园警报',
          component: 'alarm',
          visible: 'alarmInfoModal',
          data: {}
        },
        temperatureData: {
          title: '体温监测',
          component: 'temperatureMonitoring',
          visible: 'temperatureModal',
          data: {}
        },

        dormData: {
          title: '宿舍管理',
          component: 'dorm',
          visible: 'dormModal',
          data: {}
        },
        studentCheckData: {
          title: '课堂考勤',
          component: 'studentAttendance',
          visible: 'studentCheckModal',
          data: {}
        },
        partyBuildData: {
          title: '云上党建',
          component: 'classroom',
          visible: 'partyBuildModal',
          data: {}
        }
      },

      userSettingModuleList: [],
      inSchoolDataList: [],
      temperatureDataList: [],
      alarmDataList: [],
      observer: null,
      bBoundariesLoaded: false,
      bUserModuleLoaded: false,
      timer: null
    }
  },

  watch: {
    module () {
      setTimeout(() => {
        this.$refs.distributeInSchool && this.$refs.distributeInSchool._setOption()
      }, 500)
    }
  },

  created () {
    setTimeout(() => {
      this.getUserModuleList()
    }, 1000)
    this.getMapDistributeData()
  },

  mounted () {
    // 定时刷新access_token
    refreshToken.refreshLoop()

    this.getAdCode()
    const that = this
    window.onresize = this.debounce(function () {
      that.$refs.distributeInSchool.init()
      that.$refs.distribute.init()
      that.$refs.distributeAlarm.init()
    }, 1000)

    this.timer = setInterval(() => {
      this.getModuleData()
      this.getMapDistributeData()
    }, refreshTime)
  },

  beforeDestroy () {
    this.timer && clearInterval(this.timer)
  },

  methods: {

    async getAdCode () {
      const res = await api.getAdCode()
      this.city = res + ''
      // this.city = '520200'
      // this.city = '350100'
      // 生成echarts地图边界信息
      AMapBoundaries().build(this.city, (data) => {
        echarts.registerMap(this.city.toString(), data)
        const childrenList = data.features
        this.bBoundariesLoaded = true
        this.$refs.distributeInSchool.init(childrenList)
        this.$refs.distribute.init(childrenList)
        this.$refs.distributeAlarm.init(childrenList)
      })
    },
    openModal (item) {
      if (this.componentMapper[item.templateCode]) {
        this[this.componentMapper[item.templateCode].visible] = true
      }
    },

    openSettingModal (item) {
      this[item.templateCode] = true
    },

    close (item) {
      this[item] = false
    },

    debounce (fn, delay) {
      let timer = null
      return function () {
        if (timer) clearTimeout(timer)
        timer = setTimeout(fn, delay)
      }
    },

    // initialization() {
    //   if (!this.bBoundariesLoaded) return
    // },

    // 详情分布
    getMapDistributeData () {
      this.getInSchoolDetail()
      this.getTemperatureDetail()
      this.getAlarmInfoDetail()
    },

    showModuleSetting () {
      this.$refs.dialog.show()
      setTimeout(() => {
        this.$refs.setting.init(this.userSettingModuleList)
      }, 30)
    },

    closeModuleSetting () {
      this.$refs.dialog.close()
    },

    onModuleChange (data) {
      // this.getUserModuleList(data)
      this.afterGetModuleData(data)
    },

    onCarouselChange (index) {
      this.carouselIndex = index
    },

    setCarouselActive (index) {
      this.autoPlay = false
      this.$refs.carousel.setActiveItem(index)
    },

    // 获取用户设置的模块
    async getUserModuleList (data) {
      const res = await this.http.get('/district-command-center/settings/templates/byEduId')
      if (res.status === 201) return this.getSystemDefaultModule()
      if (res.status !== 200) return this.$message.error(res.msg)
      if (!res.data && !res.data.length) return this.getSystemDefaultModule()
      this.afterGetModuleData(res.data)
    },

    async getSystemDefaultModule () {
      const res = await api.getSystemDefaultModule()
      if (res.status !== 200) return this.$message.error(res.message)
      this.afterGetModuleData(res.data)
    },

    afterGetModuleData (data) {
      this.userSettingModuleList = data
      const module = [[], []]
      data.forEach(item => {
        (item.orderNum % 2 === 1) ? module[1].push(item) : module[0].push(item)
      })
      this.module = module
      this.getModuleData()
    },

    // 模块数据获取
    getModuleData () {
      this.getRealTimeStatistics()
      this.getDorm()
      this.getStudentAttendance()
      this.getTemperatureData()
      this.getBaseStatistics()
      this.getAlarmInfoData()
      this.getPartyBuild()
    },

    async getBaseStatistics () {
      const res = await api.getBaseStatistics()
      if (res.status !== 200) return
      this.$refs.baseStatistics.init(res.data)
    },

    async getAlarmInfoData () {
      const res = await api.getAlarmInfoData()
      if (res.status !== 200) return
      this.componentMapper.alarmInfoData.data = res.data
    },

    async getRealTimeStatistics () {
      const res = await api.getRealTimeStatistics()
      if (res.status !== 200) return
      this.componentMapper.inschoolData.data = res.data
    },

    // 宿舍管理
    async getDorm () {
      const res = await api.getDorm()
      if (res.status !== 200) return
      this.componentMapper.dormData.data = res.data
    },

    // 考勤
    async getStudentAttendance () {
      const res = await api.getStudentAttendance()
      if (res.status !== 200) return
      this.componentMapper.studentCheckData.data = res.data
    },

    // 体温
    async getTemperatureData () {
      const res = await api.getTemperatureData()
      if (res.status !== 200) return
      this.componentMapper.temperatureData.data = res.data
    },

    // 党建
    async getPartyBuild () {
      const res = await api.getPartyBuild()
      if (res.status !== 200) return
      this.componentMapper.partyBuildData.data = res.data
    },

    // 在校数据详情
    async getInSchoolDetail () {
      const res = await api.getInSchoolDetail()
      if (res.status !== 200) return
      this.inSchoolDataList = res.data
    },

    // 体温异常详情
    async getTemperatureDetail () {
      const res = await api.getTemperatureDetail()
      if (res.status !== 200) return
      this.temperatureDataList = res.data
    },

    // 报警详情
    async getAlarmInfoDetail () {
      const res = await api.getAlarmInfoDetail()
      if (res.status !== 200) return
      this.alarmDataList = res.data
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/body.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.distribute-tab {
  background-image: url(../../assets/img/city/distribute-tab.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tooltip {
  width: 1.52rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  background-image: url(/static/images/tooltip.png);
  padding: 0.1rem 0.2rem 0.2rem;

  div {
    padding-bottom: 0.1rem;
  }

  p {
    color: #FFC000;
    font-size: 0.14rem;
  }

  img {
    width: 0.29rem;
    height: 0.21rem
  }
}
</style>
