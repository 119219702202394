<template>
  <div class="pie" :style="{width:width,height:height}">
    <viewChart
        :chartId="random"
        :option="options"
        :width="width"
        :height="height">
    </viewChart>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import viewChart from '@/components/chart/view-chart'
export default {
    name:'guardBar',
    components:{
        viewChart
    },
    props: {
        dayList:{
            type:Array
        },
        numList:{
            type:Array
        },
        width:{
            type:String
        },
        height:{
            type:String
        }
    },
    data(){
        return {
            random:String(Math.random()),
            option : {
               xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel : {
                        formatter: function(){
                            return "";
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle:{
                            color: ['#777'],
                            width: 1,
                            type: 'dotted'
                        }
                　　}
                },
                textStyle:{
                    fontSize:'0.12rem',
                    color:'#B1CEF4'
                },
                series: [{
                    data: [],
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'top',
                        color:'#B1CEF4'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#5969F4'},
                                {offset: 1, color: '#041645'}
                            ]
                        )
                    }
                }]
            }
        }
    },
    computed:{
        options(){
            let option = JSON.parse(JSON.stringify(this.option));
            option.xAxis.data = this.dayList;
            option.series[0].data = this.numList;
            return option
        }
    }   
}
</script>

<style scoped>
.pie{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:0.12rem;
    color:#B1CEF4;
    margin:0 auto;
}
</style>
