<template>
    <transition name="slide">
        <div class="modal" v-show="modelShow">
            <div class="mask" @click.stop="$emit('handelClose')"></div>
            <div class="modal-dialog">
                <div class="close" @click.stop="$emit('handelClose')"></div>
                <div class="title">{{modelTitle}}</div>
                <div class="body scrollbar" >
					<slot name="body"></slot>
				</div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name:'modal',
    props:{
        modelShow:Boolean,
        modelTitle:String
    },
    watch: {
        modelShow (newVal, oldVue) {
            this.$nextTick(() => {
                if (newVal) {
                    this.$emit('open')
                } else {
                    this.$emit('close')
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/mixin.scss';
.modal {
    @include position(fixed);
    z-index: 10;
    transition: all .5s;
    .mask {
        @include position(fixed);
        background:#000;
        opacity: .5;
    }
    &.slide-enter-active {
        top: 0;
    }
    &.slide-leave-active {
        top: -100%;
    }
    &.slide-enter {
        top: -100%;
    }
    .modal-dialog {
        @include position(absolute, 50%, 50%, 13rem, 7.80rem);
        background: url(../../assets/img/modal-bg.png) no-repeat;
        background-size: cover;

        transform: translate(-50%, -50%);
        border-radius:.08rem;
        animation: 4s borderLight linear;
        animation-delay:0s;
        box-sizing: border-box;
        .title{
            color:#fff;
            text-align:center;
            line-height:0.48rem;
            font-size:0.18rem;
            font-weight: 700;
        }
        .close{
            width:.26rem;
            height:.26rem;
            background:url(../../assets/img/modal-close.png) no-repeat;
            background-size:cover;
            position: absolute;
            right:0.2rem;
            top:0.2rem;
            cursor: pointer;
            transition: transform .2s;
            opacity:0.8;
            &:hover {
                animation:circleRoate 5s infinite linear;  
                opacity:1;
            }
        }
        @keyframes circleRoate{  
            from{transform: rotate(0deg);}  
            to{transform: rotate(360deg);}  
        }
        @keyframes borderLight{
            0%{
                box-shadow: 0 0 0.8rem rgba($color: #0185d9, $alpha: 1.0);
            }
            25%{
                box-shadow: 0 0 0.6rem rgba($color: #0185d9, $alpha: 0.8);
            }
            50%{
                box-shadow: 0 0 0.4rem rgba($color: #0185d9, $alpha: 0.5);
            }
            70%{
                box-shadow: 0 0 0.2rem rgba($color: #0185d9, $alpha: 0.2);
            }
            100%{
                 box-shadow: 0 0 0 rgba($color: #0185d9, $alpha: 0);
            }
        } 
        .scrollbar{
            height: calc( 100% - 0.48rem );
            overflow: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            padding: 0 0.3rem 0.3rem 0.3rem;
        }
        .scrollbar::-webkit-scrollbar {/*滚动条整体样式*/
            width: 1px;    
            height: 1px;
        }
        .scrollbar::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            background: #01ddf3;
        }
        .scrollbar::-webkit-scrollbar-track {/*滚动条里面轨道*/
            background: #0185d9;
        }
    }
}
</style>
