const AMapBoundaries = function (params) {
  if (!(this instanceof AMapBoundaries)) return new AMapBoundaries(params)
  this.leftChildrenLoad = 0 // 剩余子节点加载数量
  this.callback = null
  this.boundaries = {}
  this.districtSearch = null
}

// 生成
AMapBoundaries.prototype.build = function (name, success) {
  const that = this
  if (success) this.callback = success
  if (this.districtSearch === null) {
    this.districtSearch = new window.AMap.DistrictSearch({
      extensions: 'all'
    })
  }
  this.districtSearch.search(name, function (status, result) {
    if (status !== 'complete') return
    console.log(result)
    result.districtList.forEach(item => {
      that.boundaries[item.adcode] = {
        name: item.name,
        list: []
      }
      that.adCode = item.adcode
      that.leftChildrenLoad = item.districtList.length
      // 下级边界
      item.districtList.forEach(children => {
        that._buildChildrenBoundaries(children.adcode, item.adcode)
      })
    })
  })
}

AMapBoundaries.prototype._buildChildrenBoundaries = function (childAdCode, parentAdCode) {
  const that = this
  this.districtSearch.search(childAdCode, function (status, result) {
    if (status !== 'complete') return

    result.districtList.forEach(item => {
      if (childAdCode !== 350112) {
        const points = []
        item.boundaries.forEach((top, topIndex) => {
          if (typeof points[topIndex] === 'undefined') points[topIndex] = []
          if (topIndex === 0) ;
          top.forEach(aSubPoint => points[topIndex].push([aSubPoint.lng, aSubPoint.lat]))
          // top.forEach(aSubPoint => points.push([aSubPoint.lng, aSubPoint.lat]))
        })
        that.boundaries[parentAdCode].list.push({
          type: 'Feature',
          properties: { name: item.name },
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              points
            ]
          }
        })
      }

      if (--that.leftChildrenLoad === 0) {
        that.callback && that.callback({
          type: 'FeatureCollection',
          features: that.boundaries[parentAdCode].list
        })
      }
    })
  })
}

export default AMapBoundaries
