<template>
  <div class="pie" :style="{width:width,height:height}">
    <viewChart
        :chartId="random"
        :option="options"
        :width="width"
        :height="height">
    </viewChart>
  </div>
</template>

<script>
import viewChart from '@/components/chart/view-chart'
export default {
    name:'checkPie',
    components:{
        viewChart
    },
    props: {
        data:{
            type:Array,
        },
        width:{
            type:String
        },
        height:{
            type:String
        }
    },
    data(){
        return {
            random:String(Math.random()),
            option : {
                tooltip: {
                    trigger:'item',
                    formatter: '{b} : {c}'
                },
                grid: {
                    left: '20%',
                    right: '20%',
                    bottom: '20%',
                    top:'20%',
                    containLabel: true
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        label: {
                            fontSize: 12,
                            color: '#B1CEF4'
                        },
                        data: [
                            // {value: 335, name: '访问'},
                            // {value: 310, name: '营销'},
                            // {value: 234, name: '广告'},
                            // {value: 234, name: '法律'}
                        ]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            let option =  JSON.parse(JSON.stringify(this.option));
            option.series[0].data = this.data;
            return option
        }
    }   
}
</script>

<style scoped>
.pie{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:0.12rem;
    color:#B1CEF4;
    margin:0 auto;
}
</style>