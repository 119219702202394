<template>
  <div class="myModal">
    <modal
        :modelTitle="'体温监测'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body" v-if="modelShow">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="totalTakeNum" 
                    label="今日体温监测人次">
                </el-table-column>
                <el-table-column 
                    prop="totalUnusualNum" 
                    label="今日体温异常人数">
                </el-table-column>

                <el-table-column label="学生监测率">
                    <template slot-scope="scope">
                        <div class="progressBox">
                            <progress class="progress" :value='scope.row.studentProportion' max='100'></progress>
                            <span>{{scope.row.studentProportion}}%</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="教职工监测率">
                    <template slot-scope="scope">
                        <div class="progressBox">
                            <progress class="progress progressTwo" :value='scope.row.teacherProportion' max='100'></progress>
                            <span>{{scope.row.teacherProportion}}%</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column 
                    prop="unusualMostSchoolName" 
                    label="今日体温异常最多学校">
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import { getTemperatureDetail } from '@/api/modalApi'
export default {
    components:{
        Modal
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getList();
        },
        close(){

        },
        getList(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            } 
            getTemperatureDetail(params).then(res=>{
                let list =  JSON.parse(JSON.stringify(res.data));
                if(list){
                    for(let item of list){
                        if(!item.unusualMostSchoolName){
                            item.unusualMostSchoolName = '——'
                        }
                    } 
                }
                this.list = list;
            })
        }
    }
}
</script>

<style scoped>
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
   clear: both;
}
.progressBox span{
    display: inline-block;
    vertical-align: sub;
    margin-left: 0.06rem;
}
.progress{
    width: 1rem;
    height:0.08rem;
    background-color: #263659;
}

.progress::-webkit-progress-bar {
    background-color: #263659;
    border-radius:0.1rem;
}
.progress::-webkit-progress-value {
    border-top-left-radius:0.04rem;
    border-bottom-left-radius:0.04rem;
    background: #1c8ebd;
    background: -moz-linear-gradient(left,  #1c8ebd 0%, #54dca1 100%); 
    background: -webkit-linear-gradient(left,  #1c8ebd 0%,#54dca1 100%); 
    background: linear-gradient(to right,  #1c8ebd 0%,#54dca1 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c8ebd', endColorstr='#54dca1',GradientType=1 ); 
}
.progressTwo::-webkit-progress-value {
    background: #df8848; 
    background: -moz-linear-gradient(left,  #df8848 0%, #e9c856 100%); 
    background: -webkit-linear-gradient(left,  #df8848 0%,#e9c856 100%); 
    background: linear-gradient(to right,  #df8848 0%,#e9c856 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df8848', endColorstr='#e9c856',GradientType=1 ); 

}


.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}


.myModal >>> .el-table .el-table__row td{
    padding:0.15rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom: 0.4rem;
}


.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>