<template>
  <div class="myModal">
    <modal
        :modelTitle="'预警设置'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')"
        v-loading="load"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)">
        <template slot="body">

            <div class="container">
                <el-form ref="form">
            
                    <el-form-item label="预警音效：" label-width="2.8rem">
                        <el-switch 
                            active-color="#13ce66"
                            inactive-color="#0759a0" 
                            v-model="datas.soundEffect">
                        </el-switch>
                    </el-form-item>

                    <el-form-item label="预警信息滚动次数：" label-width="2.8rem">
                        <el-input-number v-model="datas.msgRollTimes" controls-position="right" :min="0"></el-input-number>
                    </el-form-item>
                    
                   
                    <el-form-item label="预警设置：" label-width="2.8rem">
                        <el-form-item  label="报警预警：" class="formItem" label-width="1rem">
                            <el-switch 
                                active-text="开"
                                inactive-text="关"
                                active-color="#13ce66"
                                inactive-color="#0759a0" 
                                v-model="callSwitch">
                            </el-switch>
                        </el-form-item>

                        <el-form-item label="预警阈值：" class="formItem" label-width="1rem">
                            <span class="labelName">辖区报警数≥</span>
                            <el-input-number 
                                class="marLR10" 
                                v-model="datas.eduWarningOnList[0].cityThreshold" 
                                controls-position="right" 
                                :min="0">
                            </el-input-number>
                            <div class="explain">【预警类型】预警：辖区报警数达到#报警数量#</div>
                        </el-form-item>

                        <el-form-item label="" class="formItem" label-width="1rem">
                            <span class="labelName">单区报警数≥</span>
                            <el-input-number 
                                class="marLR10" 
                                v-model="datas.eduWarningOnList[0].districtThreshold" 
                                controls-position="right" 
                                :min="0">
                            </el-input-number>
                            <div class="explain">【预警类型】预警：#区县名称#报警数达到#报警数量#</div>
                        </el-form-item>

                        <div style="margin-top:0.4rem">
                            <el-form-item label="体温预警：" class="formItem" label-width="1rem">
                                <el-switch 
                                    active-text="开"
                                    inactive-text="关"
                                    active-color="#13ce66"
                                    inactive-color="#0759a0" 
                                    v-model="temperatureSwitch">
                                </el-switch>
                            </el-form-item>

                            <el-form-item label="预警阈值：" class="formItem" label-width="1rem">
                                <span class="labelName">辖区异常体温≥</span>
                                <el-input-number 
                                    class="marLR10" 
                                    v-model="datas.eduWarningOnList[1].cityThreshold" 
                                    controls-position="right" 
                                    :min="0">
                                </el-input-number>
                                <div class="explain">【预警类型】预警：辖区异常体温人数达到#异常体温人数#</div>
                            </el-form-item>

                            <el-form-item label="" class="formItem" label-width="1rem">
                                <span class="labelName">单区异常体温≥</span>
                                <el-input-number 
                                    class="marLR10" 
                                    v-model="datas.eduWarningOnList[1].districtThreshold" 
                                    controls-position="right" 
                                    :min="0">
                                </el-input-number>
                                <div class="explain">【预警类型】预警：#区县名称#异常体温人数达到#异常体温人数#</div>
                            </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
           </div>
           <div class="footer">
                <span @click="handEdit"><i class="el-icon-edit"></i>修改</span>
                <span @click="$emit('handelClose')">取消</span>
            </div>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import { getByCityEdu,postToCityEdu,postByCityEdu } from '@/api/modalApi';
export default {
    components:{
        Modal
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            load:false,
            callSwitch:false,        //报警预警
            temperatureSwitch:false, //体温预警
            datas:{
                eduId: "",
                eduWarningOnList: [
                    {
                        cityThreshold: 0,
                        districtThreshold: 0,
                        settingType: 1
                    },
                    {
                        cityThreshold: 0,
                        districtThreshold: 0,
                        settingType: 2
                    }
                ],
                msgRollTimes: 0,
                soundEffect: false
            }
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getData()
        },
        close(){

        },
        getData(){
            const callObj = {
                cityThreshold: 0,
                districtThreshold: 0,
                settingType: 1
            }
            const temperatureObj = {
                cityThreshold: 0,
                districtThreshold: 0,
                settingType: 2
            }
            this.load = true;
            const params = {
                eduId:this.userInfo.organizationIdStr,
            }
            getByCityEdu(params).then(res=>{
                this.load = false;
                let data = JSON.parse(JSON.stringify(res.data));
                
                if(data && data.eduWarningOnList){
                    for(let item of res.data.eduWarningOnList){
                        if(item.settingType == 1){
                            this.callSwitch = true;
                        }
                        if(item.settingType == 2){
                            this.temperatureSwitch = true;
                        }
                    }
                    
                    if(this.callSwitch == false){
                        data.eduWarningOnList.unshift(callObj)
                    }
                    if(this.temperatureSwitch == false){
                        data.eduWarningOnList.push(temperatureObj)
                    }
                }else{
                    data = {
                        eduWarningOnList : [callObj,temperatureObj]
                    }
                }
                this.datas = data;
            }).catch(err=>{
                this.load = false;
                this.$message.error('获取数据失败')
            })
        },
        async handEdit(){
            let datas = JSON.parse(JSON.stringify(this.datas));
            datas.eduId = this.userInfo.organizationIdStr;
            if(!this.callSwitch){
                datas.eduWarningOnList.splice(0,1)
            }
            if(!this.temperatureSwitch){
                datas.eduWarningOnList.splice(1,1)
            }
            if(!this.callSwitch && !this.temperatureSwitch){
                datas.eduWarningOnList = []
            }
            
            this.load = true;
            if(datas.id || datas.id == 0){
                // 更新设置
                await postByCityEdu(datas)

                 this.$message({
                        message: '更新成功',
                        type: 'success'
                    });
            }else{
                //增加配置
                await postToCityEdu(datas)
                 this.$message({
                        message: '增加成功',
                        type: 'success'
                    });
            }

            this.load = false

            this.getData()
        }
    }
}
</script>

<style scoped>
.marLR10{
    margin:0 0.1rem;
}
.container{
    margin-top: 0.4rem;
}
.container >>> .el-form-item__label{
    color:#fff;
    font-size:0.16rem;
}
.container >>> .el-form-item__content{
    color:#fff;
    font-size:0.16rem;
}
.container >>> .formItem .el-form-item__label{
    color:#00C6FF;
}
.container >>> .formItem .formItem_input{
    display: inline-block;
    width: 5rem;
}
.labelName{
    display: inline-block;
    width:1.1rem;
}
.container .explain{
    display: inline-block;
    width: 5rem;
    box-sizing: border-box;
    padding:0 0.05rem;
    background-color: #2575BE;
    border: 1px solid #2575BE;
    color: #fff;
    font-size:0.14rem;
}
.container >>> .el-input-number .el-input-number__decrease,
.container >>> .el-input-number .el-input-number__increase{
    background-color: #1c4466;
    color: #fff;
    border-left: 1px solid #0759A0;
    border-top:1px solid #0759A0;
    border-bottom: 1px solid #0759A0;
}

.container >>> .el-input-number .el-input__inner{
    background-color: #0759A0;
    border: 1px solid #0759A0;
    color: #fff;
}
.container >>> .el-switch .el-switch__label span{
    color:#fff;
    font-size:0.16rem;
}
.footer{
    text-align: center;
    padding-top: 0.16rem;
}
.footer > span{
    cursor: pointer;
    display: inline-block;
    width: 1.16rem;
    height: 0.38rem;
    line-height: 0.38rem;
    color: #fff;
    background: #136DBB;
    border-radius: 0.04rem;
}
.footer > span:first-child{
    margin-right: 0.34rem;
}
</style>