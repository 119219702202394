import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'


//在校数据详情列表——市级
export const getInSchoolDataDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/inSchoolDataDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//宿舍管理详情列表——市级
export const getDormDataDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/dormDataDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//体温监测详情列表——市级
export const getTemperatureDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/temperatureDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//校园报警详情列表——市级
export const getAlarmInfoDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/alarmInfoDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//云上党建详情列表——市级
export const getPartyBuildDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/partyBuildDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//课堂考勤详情列表——市级
export const getStudentCheckDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/city/dataStatsDetail/studentCheckDetail',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}






//==================预警设置=====================

//获取市教委预警配置
export const getByCityEdu= (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/byCityEdu',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}


//新增市教委预警配置
export const postToCityEdu = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/add/toCityEdu',
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        })
    })
}


//更新市教委预警配置
export const postByCityEdu = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/update/byCityEdu',
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        })
    })
}




//获取指定教委的统计页模板
export const getByEduId= (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/templates/byEduId',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}


//获取模块列表
export const getTemplate = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/getTemplate',
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        })
    })
}


//设置模板
export const postByEduId = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/add/templates/byEduId',
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        })
    })
}







