<template>
  <div class="myModal">
    <modal
        :modelTitle="'实时在校数据'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body" v-if="modelShow">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="currentStudentNum" 
                    label="在校学生">
                </el-table-column>
                <el-table-column label="学生在校率">
                    <template slot-scope="scope">
                        <pie
                            :color="['#F73853','#08245C']"
                            :data="scope.row.studentProportion">
                        </pie>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="currentTeacherNum" 
                    label="在校教师">
                </el-table-column>
                <el-table-column label="教职工在岗率">
                    <template slot-scope="scope">
                        <pie 
                            :color="['#E2BF52','#08245C']"
                            :data="scope.row.teacherProportion">
                        </pie>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import pie from '@/components/cityModal/echarts/schoolDataPie';
import { getInSchoolDataDetail } from '@/api/modalApi'
export default {
    name:'schoolDataModal',
    components:{
        Modal,
        pie
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getList()
        },
        close(){

        },
        getList(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            }
            getInSchoolDataDetail(params).then(res=>{
                this.list = res.data
            })
        }
    }
}
</script>

<style scoped>
.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}
.myModal >>> .el-table .el-table__row td{
    padding:0.15rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom: 0.4rem;
}
.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>