<template>
  <div class="myModal">
    <modal
        :modelTitle="'云上党建'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable" v-if="modelShow">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>

                <el-table-column label="今日签到人数" prop="signUpNum"></el-table-column>

                <el-table-column label="题库数" prop="itemBankNum"></el-table-column>

                <el-table-column label="文章数" prop="articleNum"></el-table-column>

                <el-table-column label="今日人均学习时长" prop="avgStudyTime"></el-table-column>

                <el-table-column label="今日完成练习人数" prop="completeNum"></el-table-column>

                <el-table-column label="最近一周学习人数趋势" width="360">
                    <template slot-scope="scope">
                       <cloudPartyLine :dayList="scope.row.dayList" :numList="scope.row.numList" :width="'3.3rem'" :height="'1.4rem'"></cloudPartyLine>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import cloudPartyLine from '@/components/cityModal/echarts/cloudPartyLine';
import { getPartyBuildDetail } from '@/api/modalApi'
export default {
    components:{
        Modal,
        cloudPartyLine
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getData()
        },
        close(){

        },
        getData(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            }
            getPartyBuildDetail(params).then(res=>{
                for(let items of res.data){
                    items.dayList = [];
                    items.numList = [];
                    if(items.latestWeekData){
                        for(let item of items.latestWeekData){
                            items.dayList.push(item.day)
                            items.numList.push(item.learningNum)
                        }
                    }
                }
                this.list = res.data
            })
        }
    }
}
</script>

<style scoped>
.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}
.myModal >>> .el-table .el-table__row{
    padding:0;
    font-size:0.14rem;
    color:#B8C8E9;
}

.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom: 0.25rem;
}

.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>