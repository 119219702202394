<template>
  <div class="pie">
    <viewChart
        :chartId="random"
        :option="options"
        :width="'0.6rem'"
        :height="'0.6rem'">
    </viewChart>
  </div>
</template>

<script>
import viewChart from '@/components/chart/view-chart'
export default {
    name:'schoolDataPie',
    components:{
        viewChart
    },
    props: {
        color:{
            type: Array
        },
        data:{
            type:[Number,String]
        }
    },
    data(){
        return {
            random:String(Math.random()),
            option:{
                color:[],
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '5%',
                    containLabel: true
                },
                series: [
                    {
                        name:'',
                        type:'pie',
		                radius: ['90%', '80%'],
                        animationDuration: 3000,
                        animationEasing: "cubicInOut",
                        data:[
                            {
                                value: 20,     
                                name:'完成率',
                                selected:true, //该数据项是否被选中
                                label: {
                                    normal: {
                                        show: true,  // 是显示标签
                                        position: 'center',
                                        fontSize: '0.12rem',
                                        color:'#fff',
                                        formatter:'{d}%'
                                    }
                                }
                            },
                            {
                                value:80, 
                                label:{
                                    normal:{
                                        show:false
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            let option = JSON.parse(JSON.stringify(this.option));
            option.color = this.color;
            let num = Number(this.data);
            option.series[0].data[0].value = num;
            option.series[0].data[1].value = num >= 100 ? 0 : 100 - num;
            return option
        }
    }   
}
</script>

<style scoped>
.pie{
    display: flex;
    justify-content: center;
}
</style>


