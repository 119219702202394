<template>
  <div class="myModal">
    <modal
        :modelTitle="'课堂考勤'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable" v-if="modelShow">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>

                <el-table-column label="今日课堂考勤次数" prop="checkTimes"></el-table-column>


                <el-table-column label="今日参与课堂考勤人次" prop="checkStuNum"></el-table-column>


                <el-table-column label="今日考勤异常人次" prop="unusualNum"></el-table-column>


                <el-table-column label="近七日课堂考勤频次最多的学校" prop="frequencyMostSchoolName" width="240"></el-table-column>


                <el-table-column label="异常状态分布" width="300">
                    <template slot-scope="scope">
                       <checkPie :data="scope.row.todayCheckData" :width="'2.2rem'" :height="'1.2rem'"></checkPie>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import checkPie from '@/components/cityModal/echarts/checkPie';
import { getStudentCheckDetail } from '@/api/modalApi'
export default {
    components:{
        Modal,
        checkPie
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getData()
        },
        close(){

        },
        getData(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            }
            getStudentCheckDetail(params).then(res=>{
                let list = res.data
                for(let items of list){
                    items.unusualNum = 0;
                    if(items.todayCheckData){
                        items.todayCheckData = JSON.parse(JSON.stringify(items.todayCheckData).replace(/stuNum/g, 'value').replace(/checkStatus/g, 'name'));
                        items.todayCheckData.forEach((item,index) => {
                            if(item.name == '正常'){
                                items.todayCheckData.splice(index,1)
                            }
                        });
                        for(let item of items.todayCheckData){
                            items.unusualNum+=item.value
                        }
                    }
                    if(!items.frequencyMostSchoolName){
                        items.frequencyMostSchoolName = '——'
                    }
                }
                this.list = list
            })
        }
    }
}
</script>

<style scoped>
.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}
.myModal >>> .el-table .el-table__row{
    padding: 0.15rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom:0.4rem;
}
.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>