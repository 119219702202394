<template>
  <div class="pie" :style="{width:width, height:height}">
    <viewChart
        :chartId="random"
        :option="options"
        :width="width"
        :height="height">
    </viewChart>
  </div>
</template>

<script>
import viewChart from '@/components/chart/view-chart'
import * as echarts from 'echarts/core';
import { constants } from 'zlib';
export default {
    name:'cloudPartyLine',
    components:{
        viewChart
    },
    props: {
        dayList:{
            type:Array,
        },
        numList:{
            type:Array,
        },
        width:{
            type:String
        },
        height:{
            type:String
        }
    },
    data(){
        return {
            random:String(Math.random()),
            option : {
               toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '5%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: [201, 154, 190, 330, 410]
                    }
                ],
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle:{
                            color: ['#777'],
                            width: 1,
                            type: 'dotted'
                        }
                　　}
                },
                color:new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                        {offset: 0, color: '#27DFFF'},
                        {offset: 1, color: '#152952'}
                    ]
                ),
                series: [
                    {
                        name: '视频广告',
                        type: 'line',
                        stack: '总量',
                        areaStyle: {},
                        data: [201, 154, 190, 330, 410]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            let option = JSON.parse(JSON.stringify(this.option));
            option.xAxis[0].data = this.dayList;
            option.series[0].data = this.numList;
            return option
        }
    }   
}
</script>

<style scoped>
.pie{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:0.12rem;
    color:#B1CEF4;
    margin: 0 auto;
    margin-top: -0.5rem;
}
</style>