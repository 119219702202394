<template>
  <div class="myModal">
    <modal
        :modelTitle="'宿舍管理'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body" v-if="modelShow">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="boarderSchoolNum" 
                    label="寄宿类学校数">
                </el-table-column>
                <el-table-column 
                    prop="dormBuildNum" 
                    label="宿舍楼数">
                </el-table-column>
                <el-table-column 
                    prop="boarderNum" 
                    label="寄宿生数">
                </el-table-column>

                <el-table-column label="寄宿生男女比例" width="240">
                    <template slot-scope="scope">
                        <div class="studentRatio">
                            <div class="iconBox clearfix">
                                <div class="manIcon fl"></div>
                                <div class="girlIcon fr"></div>
                            </div>
                            <div class="progressBox">
                                <span style="padding-right:0.05rem">{{scope.row.boarderMaleScale}}%</span>
                                <progress class="progress" :value='scope.row.boarderMaleScale' max='100'></progress>
                                <span style="padding-left:0.05rem">{{scope.row.boarderFemaleScale}}%</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="寄宿生在寝率" width="210">
                    <template slot-scope="scope">
                        <pie 
                            :color="['#50A0FF','#FFB750']"
                            :data="[scope.row.inDormProportion,scope.row.notInDormNum]">
                        </pie>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import pie from '@/components/cityModal/echarts/dormitoryPie';
import { getDormDataDetail } from '@/api/modalApi'
export default {
    components:{
        Modal,
        pie
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getList();
        },
        close(){

        },
        getList(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            } 
            getDormDataDetail(params).then(res=>{
                this.list = res.data
                for(let item of res.data){
                    if(item.boarderNum){
                        item.boarderFemaleScale =  parseFloat(((item.boarderFemaleNum / item.boarderNum) * 100).toFixed(2)); 
                        item.boarderMaleScale = parseFloat(((item.boarderMaleNum / item.boarderNum) * 100).toFixed(2));
                    }else{
                        item.boarderFemaleScale = 0;
                        item.boarderMaleScale = 0;
                    }
                    if(!item.notInDormNum){
                        item.notInDormNum = parseFloat((100 - Number(item.inDormProportion)).toFixed(2));
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
   clear: both;
}
.studentRatio{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.studentRatio .iconBox{
    width: 100%;
    box-sizing: border-box;
    padding: 0 55px;
}
.studentRatio .iconBox .manIcon{
    width:8px;
    height:20px;
    background:url('img/man.png') no-repeat;
    background-size: cover;
}
.studentRatio .iconBox .girlIcon{
    width:8px;
    height:20px;
    background:url('img/girl.png') no-repeat;
    background-size: cover;
}
.progressBox{
    display:flex;
    align-items: center;
}
.progressBox span{
    font-size:0.14rem;
    color:#B1CEF4;
}
.progress{
    width:105px;
    height: 12px;
    background: #4B9EFB;
    border-radius:12px;
}
progress::-webkit-progress-bar {
    background: #555fe9; 
    background: -moz-linear-gradient(left,  #555fe9 0%, #7079f1 100%); 
    background: -webkit-linear-gradient(left,  #555fe9 0%,#7079f1 100%); 
    background: linear-gradient(to right,  #555fe9 0%,#7079f1 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#555fe9', endColorstr='#7079f1',GradientType=1 ); 
    border-radius:12px;
}
progress::-webkit-progress-value {
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
    background: #3e98fc; 
    background: -moz-linear-gradient(left,  #3e98fc 0%, #6eb2ff 100%); 
    background: -webkit-linear-gradient(left,  #3e98fc 0%,#6eb2ff 100%); 
    background: linear-gradient(to right,  #3e98fc 0%,#6eb2ff 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e98fc', endColorstr='#6eb2ff',GradientType=1 ); 

}

.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}
.myModal >>> .el-table .el-table__row td{
    padding:0.15rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom: 0.4rem;
}
.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>