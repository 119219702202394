<template>
  <div class="myModal">
    <modal
      :modelTitle="title"
      :modelShow="modelShow"
      @open="open"
      @close="close"
      @handelClose="$emit('handelClose')">
      <template slot="body">
        <div class="container">
          <draggable chosenClass="chosen" v-model="dataList">
            <transition-group>
              <div class="item" v-for="(items,index) in dataList" :key="item.templateId">
                <el-select v-model="items.templateCode" @change="dataChange($event, index)">
                  <el-option
                    v-for="item in templateList"
                    :key="item.templateId"
                    :label="item.templateName"
                    :value="item.templateCode"
                    :disabled="flag">
                  </el-option>
                </el-select>
                <div class="imgBox">
                  <img :src="getImg(imgList[index])" alt="">
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="footer">
          <span @click="handEdit"><i class="el-icon-edit"></i>修改</span>
          <span @click="$emit('handelClose')">取消</span>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import { getByEduId,getEduBaseSettins, getTemplate, postByEduId } from '@/api/modalApi'
import draggable from 'vuedraggable'
export default {
  components:{
    Modal,
    draggable
  },
  props:{
    modelShow:Boolean
  },
  computed:{
    userInfo(){
      return this.$store.state.user.userInfo
    }
  },
  data(){
    return {
      title:'首页模块配置',
      imgList: ['shu.png', 'bao.png', 'wen.png', 'yun.png', 'su.png', 'gong.png'],
      dataList: [],
      templateList:[],
      flag:false
    }
  },
  methods: {
    open(){
      this.getIndexSet()
    },
    close(){
      this.dataList = []
      this.templateList = []
    },
    // 获取图片
    getImg (img) {
      var a = require('./img/'+img)
      return a
    },


    //获取设置列表
    async getIndexSet(){
      var params = {
        eduId: this.userInfo.organizationIdStr
      }
      let res = await getByEduId(params)
      this.dataList = res.data;
      await this.getTemplateList()
    },


    //获取模块列表
    async getTemplateList () {
      let res = await getTemplate({})
      
      let dataList = JSON.parse(JSON.stringify(this.dataList))
      let templateList = JSON.parse(JSON.stringify(this.templateList))

      if(dataList && dataList.length > 0){
        for(let items of dataList){
          templateList.forEach((item,index)=>{
            if(items.templateCode == item.templateCode){
              console.log(index)
              templateList.splice(index,1)
            }
          })
        }
        this.dataList = dataList.concat(templateList)
      }else{
        this.dataList = res.data
      }
      this.templateList = res.data;
    },


    //更新设置
    handEdit () {
      this.dataList.forEach((item,index) => {
        item.orderNum = index
      });
      const params = {
        eduId:this.userInfo.organizationIdStr,
        templateVOS:this.dataList
      }
      postByEduId(params).then(res=>{
        this.$message.success('修改成功')
        this.$emit('change',JSON.parse(JSON.stringify(this.dataList)))
      })
    },
    dataChange(e,index){
      let obj = null;
      for(let item of this.templateList){
        if(item.templateCode == e){
          obj = JSON.parse(JSON.stringify(item));
          break;
        }
      }
      this.dataList.splice(index, 1, obj)
    }
  }
}
</script>

<style scoped>
.container > div > span{
  width:5.6rem;
  margin:0.8rem auto 0;
  display: flex;
  flex-wrap:wrap;
}
.container .item{
  justify-content: center;
  margin:0 auto;
  margin-bottom: 0.2rem;
}
.item >>> .el-select {
  width:2.6rem;
  margin-bottom:0.05rem;
}
.item >>> .el-select .el-input__inner{
  background: #0C508C;
  color: #FFFFFF;
  height: 0.3rem;
  border: none;
  border-radius: 0;
}
.item .imgBox{
  width: 2.6rem;
  height: 0.74rem;
  background: url('../../assets/img/modal/imgBk.png') no-repeat;
  background-size: 100%;
  padding: 0.17rem 0;
  text-align: center;
  box-sizing: border-box;
}
.item .imgBox img{
  width: 0.4rem;
  height: 0.4rem;
}
.footer{
  text-align: center;
  padding-top: 0.16rem;
}
.footer > span{
  cursor: pointer;
  display: inline-block;
  width: 1.16rem;
  height: 0.38rem;
  line-height: 0.38rem;
  color: #fff;
  background: #136DBB;
  border-radius: 0.04rem;
}
.footer > span:first-child{
  margin-right: 0.34rem;
}
.chosen >>> .el-select .el-input__inner{
  background-color: #67C23A;
}
.chosen .imgBox{
  background-image: none;
  border:1px solid #67C23A;
}
</style>
