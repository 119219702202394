<template>
  <div class="myModal">
    <modal
        :modelTitle="'校园报警'" 
        :modelShow="modelShow" 
        @open="open"
        @close="close"
        @handelClose="$emit('handelClose')">
        <template slot="body" v-if="modelShow">
            <el-table
            class="list"
            :data="list" 
            style="width: 100%"
            height="100%"
            align="center"
            ref="dormTable">
                <el-table-column label="区县">
                    <template slot-scope="scope">
                        <div style="color:#FFA800">{{scope.row.eduName}}</div>
                    </template>
                </el-table-column>

                <el-table-column label="今日报警数" prop="todayAlarmNum"></el-table-column>

                <el-table-column label="接警数" prop="todayReadedNum"></el-table-column>

                <el-table-column label="平均接警时间" prop="avgReadedTime"></el-table-column>

                <el-table-column label="最新报警" prop="latestAlarmTime"></el-table-column>

                <el-table-column label="近一周警情" width="400" style="width:4rem">
                    <template slot-scope="scope">
                       <guardBar :dayList="scope.row.dayList" :numList="scope.row.numList" :width="'4rem'" :height="'2rem'"></guardBar>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/cityModal/modal2';
import guardBar from '@/components/cityModal/echarts/guardBar';
import { getAlarmInfoDetail } from '@/api/modalApi';
import { formatDate } from '@/util/myTime'
export default {
    components:{
        Modal,
        guardBar
    },
    props:{
        modelShow:Boolean
    },
    data(){
        return {
            list:[]
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.userInfo
        }
    },
    methods: {
        open(){
            this.getData();
        },
        close(){

        },
        getData(){
            const params = {
                eduId:this.userInfo.organizationIdStr
            } 
            getAlarmInfoDetail(params).then(res=>{
                let data = JSON.parse(JSON.stringify(res.data));
                for(let items of data){
                    items.dayList = [];
                    items.numList = [];

                    if(items.latestAlarmTime){
                        items.latestAlarmTime = formatDate(items.latestAlarmTime).format("yyyy-MM-dd hh:mm")
                    }else{
                        items.latestAlarmTime = '——'
                    }
                    
                    if(items.avgReadedTime <= 10000){
                        items.avgReadedTime = formatDate(items.avgReadedTime*1000).format("mm分ss秒")
                    }else if(items.avgReadedTime*1000 >= 3600000){ //时
                        items.avgReadedTime = formatDate(items.avgReadedTime*1000).format("hh时mm分ss秒")
                    } else if (items.avgReadedTime*1000 >= 43200000 ) { //天
                        items.avgReadedTime = formatDate(items.avgReadedTime*1000).format("dd日hh时mm分ss秒")
                    } else {
                        items.avgReadedTime = formatDate(items.avgReadedTime*1000).format("MM月dd日hh时mm分ss秒")
                    }
                    for(let item of items.latestWeekData){
                        items.dayList.push(item.day)
                        items.numList.push(item.alarmNum)
                    }
                }
                this.list = data;
                console.log(this.list)
            })
        }
    }
}
</script>

<style scoped>
.myModal >>> .el-table, 
.myModal >>> .el-table tr, 
.myModal >>> .el-table td, 
.myModal >>> .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.myModal >>> .el-table::before{
    background-color: transparent!important;
}
.myModal >>> .el-table th{
    font-size:0.15rem;
    color:#00A2FF;
}
.myModal >>> .el-table .el-table__row td{
    padding:0.1rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.myModal >>> .el-table .el-table__row:last-child td{
    padding-bottom: 0.4rem;
}
.myModal >>> .el-table__header-wrapper{
    margin-top:0.3rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.myModal >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>