// 获取当天时间
export function getNow(type) {
    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth()
    let date = now.getDate()
    let hour = now.getHours()
    let minute = now.getMinutes()
    month = month + 1
    month = month.toString().padStart(2, "0")
    date = date.toString().padStart(2, "0")
    hour = hour.toString().padStart(2, "0")
    minute = minute.toString().padStart(2, "0")
    let value = now
    switch(type){
        case 0:
        value = `${year}-${month}-${date}`
        break
        case 1:
        value = `${year}-${month}-${date}`+ ' ' +`${hour}:${minute}`
        break
        case 2:
        value = `${year}-${month}-1`
        break
        default:
        value = ''
    }
    return value
}

//获取上一个月
export function getPreMonth(data) {
    var arr = date.split('-');
    var year = arr[0]; 
    var month = arr[1]; 
    var day = arr[2]; 
    var days = new Date(year, month, 0);
    days = days.getDate();
    var year2 = year;
    var month2 = parseInt(month) - 1;
    if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}

//获取上一个月天数
export function getPreMonthEnd(data){
    var arr = date.split('-');
    var year = arr[0]; 
    var month = arr[1]; 
    var day = arr[2]; 
    var days = new Date(year, month, 0);
    days = days.getDate(); 
    var t2 = year + '-' + month + '-' + days;
    return t2
}

//获取下一个月
export function getNextMonth(date){
    var arr = date.split('-');
    var year = arr[0]; 
    var month = arr[1]; 
    var day = arr[2]; 
    var days = new Date(year, month, 0);
    days = days.getDate(); 
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 == 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}

//获取下一个月天数
export function getNextMonthEnd(date){
    var arr = date.split('-');
    var year = arr[0]; 
    var month = arr[1]; 
    var day = arr[2]; 
    var days = new Date(year, month, 0);
    days = days.getDate(); 
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 == 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + days;
    return t2;
}


//本周
export function getCurrentWeek() {
    //获取当前时间
    const currentDate = new Date()
    //返回date是一周中的某一天
    const week = currentDate.getDay()
    //一天的毫秒数
    const millisecond = 1000 * 60 * 60 * 24
    //减去的天数
    const minusDay = week != 0 ? week - 1 : 6
    //本周 周一
    const monday = new Date(currentDate.getTime() - minusDay * millisecond)
    //本周 周日
    const sunday = new Date(monday.getTime() + 6 * millisecond)
    return [formatDate(monday).format("yyyy-MM-dd"),formatDate(sunday).format("yyyy-MM-dd")]
}

//获取上一周
export function getPrevWeek(date){
    var arr = date.split('-');
    const Time = new Date(arr[0], arr[1]-1, arr[2], 0, 0, 0); 
    let weekNum = Time.getDay() 
    weekNum = weekNum == 0 ? 7 : weekNum
    let lastDate = new Date(Time.getTime() - weekNum * 24 * 60 * 60 * 1000)
    let fitstDate = new Date(
    Time.getTime() - (weekNum + 6) * 24 * 60 * 60 * 1000
    )
    let startDate = `${fitstDate.getFullYear()}-${
    fitstDate.getMonth() + 1 < 10
        ? '0' + (fitstDate.getMonth() + 1)
        : fitstDate.getMonth() + 1
    }-${
    fitstDate.getDate() < 10
        ? '0' + fitstDate.getDate()
        : fitstDate.getDate()
    }`
    let endDate = `${lastDate.getFullYear()}-${
    lastDate.getMonth() + 1 < 10
        ? '0' + (lastDate.getMonth() + 1)
        : lastDate.getMonth() + 1
    }-${
    lastDate.getDate() < 10 ? '0' + lastDate.getDate() : lastDate.getDate()
    }`
    return [startDate, endDate]
}

 //获取下一周
 export function getNextvWeek(date) {
    var arr = date.split('-');
    const Time = new Date(arr[0], arr[1]-1, arr[2], 0, 0, 0); 
    let weekNum = Time.getDay()
    weekNum = weekNum == 0 ? 7 : weekNum

    let fitstDate = new Date(
      Time.getTime() + (7 - weekNum + 1) * 24 * 60 * 60 * 1000
    )
    let lastDate = new Date(
      Time.getTime() + (7 - weekNum + 7) * 24 * 60 * 60 * 1000
    )
    let startDate = `${fitstDate.getFullYear()}-${
      fitstDate.getMonth() + 1 < 10
        ? '0' + (fitstDate.getMonth() + 1)
        : fitstDate.getMonth() + 1
    }-${
      fitstDate.getDate() < 10
        ? '0' + fitstDate.getDate()
        : fitstDate.getDate()
    }`
    let endDate = `${lastDate.getFullYear()}-${
      lastDate.getMonth() + 1 < 10
        ? '0' + (lastDate.getMonth() + 1)
        : lastDate.getMonth() + 1
    }-${
      lastDate.getDate() < 10 ? '0' + lastDate.getDate() : lastDate.getDate()
    }`
    return [startDate, endDate]
}

//时间格式转化
//使用：formatDate(date).format("yyyy-MM-dd hh:mm:ss EEE");
export function formatDate(date){
    Date.prototype.format = function(format){
        var o = {
            "M+" : this.getMonth()+1,                 //月份
            "d+" : this.getDate(),                    //日
            "h+" : this.getHours(),                   //小时
            "m+" : this.getMinutes(),                 //分
            "s+" : this.getSeconds(),                 //秒
            "q+" : Math.floor((this.getMonth()+3)/3), //季度
            "S"  : this.getMilliseconds()             //毫秒
        };
        if(/(y+)/.test(format)) { 
            format = format.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
        }
        var week = {         
            "0" : "一",         
            "1" : "二",         
            "2" : "三",         
            "3" : "四",         
            "4" : "五",         
            "5" : "六",         
            "6" : "日"        
        };  
        if(/(E+)/.test(format)){         
            format=format.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "星期" : "周") : "")+week[this.getDay()+""]);         
        }
        for(var k in o) { 
            if(new RegExp("("+ k +")").test(format)) { 
                format = format.replace(RegExp.$1, RegExp.$1.length==1 ? o[k] : ("00"+ o[k]).substr((""+ o[k]).length)); 
            } 
        }
        return format;
    }
    var now = new Date(date);
    return now
}
